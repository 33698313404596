import { useCallback, useEffect, useState } from "react";

import BlogWidget from "./BlogWidget";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Ready from "../Ready";
import { useTranslation } from "react-i18next";

export default function Blog(props) {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 9;

  const { t, i18n } = useTranslation();

  const getTimeAgo = useCallback((date, language) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
    };

    const getSpanishUnit = (unit, count) => {
      const units = {
        year: count === 1 ? 'año' : 'años',
        month: count === 1 ? 'mes' : 'meses',
        week: count === 1 ? 'semana' : 'semanas',
        day: count === 1 ? 'día' : 'días',
        hour: count === 1 ? 'hora' : 'horas',
        minute: count === 1 ? 'minuto' : 'minutos',
      };
      return units[unit];
    };

    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
      const interval = Math.floor(seconds / secondsInUnit);
      if (interval >= 1) {
        return language.startsWith('es') 
          ? `hace ${interval} ${getSpanishUnit(unit, interval)}`
          : `${interval} ${unit}${interval === 1 ? '' : 's'} ago`;
      }
    }
    return language.startsWith('es') ? 'ahora mismo' : 'just now';
  }, []);

  const fetchBlogData = useCallback(async (language) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}?key=${process.env.REACT_APP_KEY}&filter=tag:${language}&page=${page}&limit=${postsPerPage}&include=count.posts,tags`
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedBlog = data.posts.map((blogData) => {
        const wordCount = blogData.html?.split(/\s+/)?.length || 0;
        const readingTime = Math.ceil(wordCount / 200);
        
        const publishedDate = new Date(blogData.published_at);
        const timeAgo = getTimeAgo(publishedDate, i18n.language);

        return {
          id: blogData.uuid,
          slug: blogData.slug,
          title: blogData.title,
          description: blogData.excerpt.replace(/\n/g, ""),
          image: blogData.feature_image,
          tags: blogData.tags || [],
          readingTime,
          publishedAt: timeAgo,
        };
      });
      setBlogData(transformedBlog);
      setTotalPages(Math.ceil(data.meta.pagination.total / postsPerPage));
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  }, [page, i18n.language, getTimeAgo]);

  useEffect(() => {
    if (i18n.language === "es-ES" || i18n.language === "es") {
      fetchBlogData("es");
    } else {
      fetchBlogData("en");
    }
  }, [fetchBlogData, i18n.language]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  const renderPagination = () => {
    return (
      <div className="flex justify-center space-x-4 my-8">
        <button
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          disabled={page === 1}
          className={`px-4 py-2 rounded ${
            page === 1
              ? 'bg-gray-200 text-gray-500'
              : 'bg-ablue-600 text-white hover:bg-ablue-700'
          }`}
        >
          {t("previous")}
        </button>
        <span className="px-4 py-2">
          {t("page")} {page} {t("of")} {totalPages}
        </span>
        <button
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={page === totalPages}
          className={`px-4 py-2 rounded ${
            page === totalPages
              ? 'bg-gray-200 text-gray-500'
              : 'bg-ablue-600 text-white hover:bg-ablue-700'
          }`}
        >
          {t("next")}
        </button>
      </div>
    );
  };

  const LoadingOverlay = () => (
    <div className="absolute inset-0 bg-white bg-opacity-75 z-10 flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-ablue-600"></div>
        <span className="mt-4 text-ablue-600">{t("loading")}</span>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{t("blog-head")} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("blog-desc")} />
        <meta property="og:title" content={t("blog-head")} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t("blog-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="relative pt-44 m-auto bg-white">
        {loading && <LoadingOverlay />}
        <div className={`transition-opacity duration-300 ${loading ? 'opacity-50' : 'opacity-100'}`}>
          <div className="m-auto top-0 xl:w-full w-5/6 text-center">
            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              BLOG
            </span>
            <h1 className="text-4xl xl:text-5xl text-nblue-900 font-bold">
              {t("blog-title")}
            </h1>
            <p className="font-light mt-4  text-lg text-agray-500 xl:w-1/2 m-auto ">
              {t("blog-desc")}
            </p>
          </div>

          <div className="hidden md:block relative mt-10 mx-4 xl:mx-40 md:mx-16">
            <div className="grid grid-cols-3 gap-x-8 gap-y-16">
              {blogData.map((blog, index) => (
                <Link
                  to={`/blog/detail/${blog.slug}`}
                  className="col-span-3 xl:col-span-1 lg:col-span-1 md:col-span-1 group"
                  key={index}
                >
                  <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 md:px-0 h-full bg-white rounded-xl shadow-sm transition-all duration-300 group-hover:shadow-lg group-hover:translate-y-[-4px]">
                    <div className="relative">
                      <img
                        src={
                          blog.image ??
                          require("../../assets/img/blog-default.png")
                        }
                        alt="blog"
                        className="w-full h-52 object-cover rounded-t-xl"
                      ></img>
                    </div>
                    <div className="p-6">
                      <div className="flex flex-wrap justify-center gap-2 mb-3">
                        {blog.tags.slice(0, 2).map((tag, tagIndex) => (
                          <span
                            key={tagIndex}
                            className="px-3 py-1 text-sm bg-ablue-100 text-ablue-600 rounded-full"
                          >
                            {tag.name}
                          </span>
                        ))}
                      </div>
                      
                      <div className="flex justify-center items-center space-x-4 text-sm text-agray-500 mb-3">
                        <span>{blog.publishedAt}</span>
                        <span>•</span>
                        <span>{blog.readingTime} {t("min-read")}</span>
                      </div>

                      <h2 className="text-2xl text-nblue-900 font-bold line-clamp-2 mb-3">
                        {blog.title}
                      </h2>
                      <p className="font-light text-base text-agray-500 line-clamp-2">
                        {blog.description}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            {renderPagination()}
          </div>
          <div className="flex md:hidden justify-center">
            <BlogWidget></BlogWidget>
          </div>
          <Ready></Ready>
        </div>
      </div>
    </>
  );
}
