import "swiper/css";

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Autoplay } from "swiper";
import ContactUs from "./ContactUs";
import FAQs from "./FAQs";
import { Helmet } from "react-helmet";
import IndustryComponent from "./IndustryComponent";
import Loading from "../components/shared/Loading";
import Ready from "./Ready";
import WeHaveItAll2 from "./WeHaveItAll2";
import WhyChooseUs from "./WhyChooseUs";
import { useCasesData } from "../useCasesData";
import { useTecnologyData } from "../useTecnologyData";
import { useTranslation } from "react-i18next";

export default function Technology() {
  const [swiper, setSwiper] = useState(null);

  // SwiperCore.use([Navigation, Autoplay]);

  let { slug } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");

  const technologies = useTecnologyData();
  const case_studies = useCasesData();

  const [index, setIndex] = useState(0);

  const [technology] = useState(technologies);
  const [studies] = useState(case_studies);

  const [cases, setCases] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qParam = searchParams.get("q");

  useEffect(() => {
    try {
      window.scrollTo({ top: 0, behavior: "smooth" });

      const filterSlug = technology.filter((item) => item.slug === slug);

      if (filterSlug.length === 0) {
        navigate("page-not-found");
      }

      setData(filterSlug[0]);
    } catch (error) {
      console.log(error);
    }
  }, [slug, navigate, technology]);

  useEffect(() => {
    if (data && cases) {
      setLoading(false);
    }
  }, [data, studies, cases]);

  useEffect(() => {
    try {
      if (data) {
        const filter_cases = studies.filter((item) =>
          item.tecnologies.some((technology) => technology.name === data.name)
        );

        setCases(filter_cases);
      }
    } catch (error) {
      console.log(error);
    }
  }, [data, studies]);

  return (
    <Fragment>
      <Helmet>
        <title>{t("our-work")} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {!loading ? (
        <Fragment>
          <Helmet>
            <title>{data.name}</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content={data.desc} />
            <meta property="og:title" content={data.name} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={data.image_cover} />
            <meta property="og:description" content={data.desc} />
            <meta property="og:url" content={window.location.href} />
          </Helmet>
          <div className={`relative pt-14 md:pt-36 xl:pt-28 m-auto bg-gray-100 ${qParam === "landing" && "pb-32"}`}>
            {/* MAIN */}
            <main className="mx-4 lg:mx-40 tv:mx-96 px-4 lg:px-0 my-8 pt-16">
              <div className="lg:w-[114px] lg:h-[112px] m-auto">
                <img
                  className="pb-[24px] rounded-2xl m-auto"
                  src={data.main_icon}
                  alt="cover"
                />
              </div>
              <div className="text-center">
                <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                  {data.name}
                </span>
                <h2 className="text-5xl text-nblue-900 font-bold">
                  {t(data.title)}
                </h2>
                <p className="font-light mt-4  pb-28 text-lg text-agray-500  m-auto ">
                  {t(data.description)}
                </p>
              </div>
            </main>
            {/* SERVICES */}
            <div className="bg-white px-4 md:px-40 tv:px-96 py-12">
              <div className="pt-16">
                <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                  {t("services")}
                </span>
                <h1 className="text-5xl text-nblue-900 font-bold">
                  {t(data.service_title)}
                </h1>
                <p className="font-light mt-4 text-lg text-agray-500 m-auto ">
                  {t(data.service_description)}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-8">
                  {data.services.map((item, index) => (
                    <div className="col-span-1" key={index}>
                      <h2 className="text-2xl text-nblue-900 font-bold">
                        {t(item.name)}
                      </h2>
                      <p className="font-light mt-4 text-lg text-agray-500  m-auto ">
                        {t(item.description)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* WHAT WE DO */}
            <div className="bg-white mb-12">
              <div className="mx-4 md:mx-40">
                <div className="relative overflow-hidden">
                  <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-6">
                    <div className="md:order-2">
                      <div className="mt-6">
                        <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                          {t("what-we-do")}
                        </span>
                        <h2 className="text-5xl text-nblue-900 font-bold">
                          {t(data.what_we_do_title)}
                        </h2>
                        <div className="font-light mt-4 text-lg text-agray-500 m-auto"
                             dangerouslySetInnerHTML={{ __html: t(data.what_we_do_description) }}
                        />
                      </div>
                    </div>
                    <div className="mt-12 md:mt-0 bg-agray-100 h-[406px] w-full md:h-[512px] md:w-[448px] rounded-[28px]">
                    <img
                      className="w-full h-full object-cover rounded-[28px]"
                      src={data.image_what_we_do}
                      alt="what-we-do-img"
                    />
                    </div>
                    
                  </div>
                  {/* SLIDER */}
                  {!loading && cases.length > 0 && (
                    <Fragment>
                      <div className="mt-[233px] pt-16">
                        <div className="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-[128px]">
                          <div className="lg:col-start-1 ">
                            <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                              {t("our-work")}
                            </span>
                            <h2 className="text-5xl text-nblue-900 font-bold">
                              {cases[index].name}
                            </h2>
                            <p className="font-light mt-4 text-lg text-agray-500 m-auto line-clamp-3">
                              {t(cases[index].challenge)}
                            </p>
                            <div className="mt-9">
                              <button
                                className="justify-center w-64 h-14 px-[39px] py-[12px] border rounded-[28px] shadow-sm text-[14px] font-manrope text-white bg-gradient-to-r from-ablue-500 to-ablue-900 bg-animate-slow hover:from-agray-500 hover:to-agray-500 flex my-8 items-center"
                                onClick={(e) =>
                                  navigate(
                                    `/our-work/detail/${cases[index].slug}`
                                  )
                                }
                              >
                                {t("see-more")}
                              </button>
                            </div>
                          </div>
                          <div className="lg:col-start-2 lg:w-[448px] lg:h-[512px] ">
                            <Swiper
                              className="  h-96  rounded-3xl "
                              slidesPerView={1}
                              onInit={(ev) => {
                                setSwiper(ev);
                                setIndex(ev.activeIndex);
                              }}
                              modules={[Autoplay]}
                              autoplay={{ delay: 2000 }}
                              spaceBetween={50}
                              onSlideChange={(e) => setIndex(e.realIndex)}
                              onSwiper={(swiper) =>
                                setIndex(swiper.activeIndex)
                              }
                            >
                              {cases.map((item, index) => (
                                <SwiperSlide
                                  key={index}
                                  onClick={(e) =>
                                    navigate(
                                      `/our-work/detail/${cases[index].slug}`
                                    )
                                  }
                                >
                                  <img
                                    className="m-auto my-4 md:my-0"
                                    src={item.image_cover}
                                    alt="cover"
                                  />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                            <div className="flex h-10 w-auto float-left mt-6 text-ablue-900">
                              <button
                                onClick={() => {
                                  swiper.slidePrev();
                                }}
                                id="swiper-backward"
                              >
                                <ArrowLeftIcon className="mr-8 h-9 w-9 hover:text-sky-400"></ArrowLeftIcon>
                              </button>
                              <button
                                onClick={() => {
                                  swiper.slideNext();
                                }}
                                id="swiper-forward"
                              >
                                <ArrowRightIcon className="h-9 w-9 hover:text-sky-400"></ArrowRightIcon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <IndustryComponent></IndustryComponent>
            {qParam === "landing" && <FAQs></FAQs>}
            <WeHaveItAll2 />
          </div>
          {qParam === "landing" && <WhyChooseUs></WhyChooseUs>}
          {qParam === "landing" ? <ContactUs></ContactUs> : <Ready></Ready>}
        </Fragment>
      ) : (
        <div className="relative pt-14 md:pt-36 xl:pt-28 m-auto">
          <Loading />
        </div>
      )}
    </Fragment>
  );
}
